  import React, { lazy, Suspense, useEffect } from "react";
  import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

  // components
  import AdminNavbar from "../components/Navbars/AdminNavbar"
  import Sidebar from "../components/Sidebar/Sidebar"
  import HeaderStats from "../components/Headers/HeaderStats"
  import Loader from "../components/Loader/Loader";
  import { verifyToken } from "../services/auth";
  import { toast } from "sonner";
  const SalesReport = lazy(() => import("../views/admin/NewSalesReport"));
  const RefundReport = lazy(() => import("../views/admin/RefundReport"));

  // views
  // import Dashboard from "../views/admin/Dashboard";
  // import Maps from "../views/admin/Maps";
  // import Settings from "../views/admin/Settings";
  // import Tables from "../views/admin/Tables";
  // import OrderDetails from "../views/admin/OrderDetails";
  // import ManageInvoice from "../views/admin/ManageInvoice";
  const LoaderTwo = lazy(() => import("../components/Loader/LoaderTwo"));
  const Dashboard = lazy(() => import("../views/admin/Dashboard"));
  const Maps = lazy(() => import("../views/admin/Maps"));
  const Settings = lazy(() => import("../views/admin/Settings"));
  const Tables = lazy(() => import("../views/admin/Tables"));
  const OrderDetails = lazy(() => import("../views/admin/OrderDetails"));
  const ManageInvoice = lazy(() => import("../views/admin/ManageInvoice"));
  const ManageRefunds = lazy(() => import("../views/admin/ManageRefunds"));
  const FooterAdmin = lazy(()=> import("../components/Footers/FooterAdmin"))
  const RefundApprovals = lazy(()=> import("../views/admin/RefundApprovals"));
  const Overview = lazy(()=> import("../views/admin/Overview"));
  const UnplacedOrderRefunds = lazy(()=> import("../views/admin/UnplacedOrderRefunds"))
  const ProActiveReachOut = lazy(()=> import("../views/admin/ProActiveReachOut"))
  const CrmTickets = lazy(() => import("../views/admin/CrmTickets"));
  const TicketDetails = lazy( () => import("../views/admin/TicketDetails"));


  export default function Admin() {
    const token = localStorage.getItem("token")
    const navigate = useNavigate();
    
    useEffect(() => {
      if (!token || token === "") {
        navigate("/auth/login")
      } else {
        (async()=>{
          const res = await verifyToken();
          if (res.status !== 200){
            localStorage.setItem("session", "expired")
            navigate("/auth/login")
            toast.error("Seesion Expired, Please Login")
          }
        })()
      }
    }, [])

    return (
      <>
        <Sidebar />
        <div className="relative md:ml-64 bg-blueGray-100">
          <AdminNavbar />
          <HeaderStats />  {/* Header */}
          <div className="px-4 md:px-10 mx-auto w-full -m-32">
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="overview" element={<Overview />} />
              <Route path="maps" element={<Maps />} />
              <Route path="orders" element={<Settings />} />
              <Route path="invoice" element={<ManageInvoice />} />
              <Route path="manage-users" element={<Tables />} />
              <Route path="order-details" element={<OrderDetails />} />
              <Route path="loader" element={<LoaderTwo />} />
              <Route path="manage-refunds" element={<ManageRefunds />} />
              <Route path="crm-tickets" element={<CrmTickets />} />
              <Route path="refund-approve" element={<RefundApprovals />} />
              <Route path="unplaced-order" element={<UnplacedOrderRefunds />} />
              <Route path="sales-report" element={<SalesReport />} />
              <Route path="refund-report" element={<RefundReport />} />
              <Route path="ticket-detail" element={<TicketDetails />} />
              <Route path="pro-active-reachout" element={<ProActiveReachOut />} />
              <Route path="" element={<Navigate to="/admin/overview" />} />
            </Routes>
            <FooterAdmin />
            </Suspense>
          </div>
        </div>
      </>
    );
  }
