import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { updateBankDetail } from '../services/order';
import { toast } from 'sonner';

const BankDetails = () => {
  const [activeTab, setActiveTab] = useState('bank'); // 'bank' or 'upi'
  const { refundId } = useParams();
  const [decryptRefundId, setDecryptRefundId] = useState(null);
  const [errors, setErrors] = useState({});

  const keyHex = '35e18cf13b397730362fdf35d664f97594e4ff83cea983a74587a2a6328332f6';
  const ivHex = 'e0ace631b7121dfa1dbe17863fc8856e';

  const [bankFormValues, setBankFormValues] = useState({
    accountHolderName: '',
    ifscCode: '',
    accountNumber: '',
    branchName: '',
    address: '',
  });


  const decryptToken = async (encryptedBase64, keyHex, ivHex) => {
    try {
      const key = CryptoJS.enc.Hex.parse(keyHex);
      const iv = CryptoJS.enc.Hex.parse(ivHex);
      // Convert URL-safe Base64 back to standard Base64 if needed
      const normalizedBase64 = encryptedBase64.replace(/_/g, '/').replace(/-/g, '+');
  
      // Decrypt the encrypted data
      const decryptedBytes = CryptoJS.AES.decrypt(normalizedBase64, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
  
      const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
  
      // Ensure decryption was successful
      if (!decryptedText) {
        throw new Error("Decryption failed: Invalid data or incorrect key/IV.");
      }
  
      return decryptedText;
    } catch (error) {
      console.error(" Error decrypting token:", error.message);
      return null; // Return null to handle errors gracefully
    }
  };
  


  const [upiFormValues, setUpiFormValues] = useState({
    upiId: '',
  });

  const validateBankForm = () => {
    let newErrors = {};
    if (!bankFormValues.accountHolderName.trim()) newErrors.accountHolderName = 'Account holder name is required';
    if (!bankFormValues.ifscCode.trim()) newErrors.ifscCode = 'IFSC Code is required';
    else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(bankFormValues.ifscCode)) newErrors.ifscCode = 'Invalid IFSC Code';
    if (!bankFormValues.accountNumber.trim()) newErrors.accountNumber = 'Account Number is required';
    else if (!/^[0-9]{9,18}$/.test(bankFormValues.accountNumber)) newErrors.accountNumber = 'Invalid Account Number';
    if (!bankFormValues.branchName.trim()) newErrors.branchName = 'Branch Name is required';
    if (!bankFormValues.address.trim()) newErrors.address = 'Address is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateUpiForm = () => {
    let newErrors = {};
    if (!upiFormValues.upiId.trim()) newErrors.upiId = 'UPI ID is required';
    else if (!/^[a-zA-Z0-9.-_]+@[a-zA-Z]{3,}$/.test(upiFormValues.upiId)) newErrors.upiId = 'Invalid UPI ID';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleBankChange = (e) => {
    const { name, value } = e.target;
    setBankFormValues({ ...bankFormValues, [name]: value });
  };

  const handleUpiChange = (e) => {
    const { name, value } = e.target;
    setUpiFormValues({ ...upiFormValues, [name]: value });
  };

  const handleBankSubmit = async (e) => {
    e.preventDefault();
    // Validate the form before submitting
    if (validateBankForm()) {
      console.log('Bank Details Submitted:', bankFormValues, activeTab);
      try {
        const response = await updateBankDetail({ bankFormValues, activeTab, decryptRefundId });
        console.log('Response from updateBankDetail:', response);
        const { data, status } = response || {};
        console.log('data ---->>>,<<<<<', data)
        if (status === 200 || status === 201) {
          toast.success(data?.message)
          setBankFormValues({
            accountHolderName: '',
            ifscCode: '',
            accountNumber: '',
            branchName: '',
            address: '',
          });
        } else if (status === 404) {
          toast.error(data?.message);
        } else {
          toast.error(data?.message || "Something went wrong. Please try again.");
        }
        setBankFormValues({
          accountHolderName: '',
          ifscCode: '',
          accountNumber: '',
          branchName: '',
          address: '',
        });
      } catch (error) {
        console.error('Error updating bank details:', error);
      }
    }
  };

  const decrypt = async () => {
    const decryptedData = await decryptToken(refundId, keyHex, ivHex);
    console.log('decrypted refund id--->>', decryptedData)
    setDecryptRefundId(decryptedData);
  };


  useEffect(() => {
    if (refundId) {
      console.log('refund id--->>', refundId)
      decrypt();
    }
    console.log('refundId', refundId);
  }, [refundId]);

  const handleUpiSubmit = async (e) => {
    e.preventDefault();
    if (validateUpiForm()) {
      console.log('UPI Details Submitted:', upiFormValues);
      try {
        const response = await updateBankDetail({ upiFormValues, activeTab, decryptRefundId });
        console.log('Response from updateBankDetail:', response);
        const { data, status } = response || {};
        console.log('data ---->>>,<<<<<', data)
        if (status === 200 || status === 201) {
          setUpiFormValues({
            upiId: '',
          });
          toast.success(data?.message)
        } else if (status === 404) {
          toast.error(data?.message);
        } else {
          toast.error(data?.message || "Something went wrong. Please try again.");
        }
        setUpiFormValues({
          upiId: '',
        });
      } catch (error) {
        console.error('Error updating bank details:', error);
      }
    }
  };

  return (
    <>  <header className="h-20 flex items-center px-6 shadow-md">
    <img 
      src="https://www.daburshop.com/cdn/shop/files/Dabur-Logo.wine_1.png?v=1724404379&width=90" 
      alt="Logo" 
      className="h-20 w-30 object-contain" 
    />
  </header>
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-md mt-10">
        <div className="mb-4 flex justify-between">
          <button
            className={`w-1/2 py-2 px-4 ${activeTab === 'bank' ? 'bg-[#02500999] text-white' : 'bg-gray-200'} rounded-l-md`}
            onClick={() => setActiveTab('bank')}
          >
            Bank Details
          </button>
          <button
            className={`w-1/2 py-2 px-4 ${activeTab === 'upi' ? 'bg-[#02500999] text-white' : 'bg-gray-200'} rounded-r-md`}
            onClick={() => setActiveTab('upi')}
          >
            UPI Details
          </button>
        </div>

        {activeTab === 'bank' && (
          <form onSubmit={handleBankSubmit}>
            <h2 className="text-2xl font-bold mb-4 text-center">Bank Details</h2>
            {Object.keys(bankFormValues).map((field) => (
              <div className="mb-4" key={field}>
                <label className="block text-sm font-medium text-gray-700">{field.replace(/([A-Z])/g, ' $1').trim().replace(/^./, str => str.toUpperCase())}</label>
                <input
                  type="text"
                  name={field}
                  value={bankFormValues[field]}
                  onChange={handleBankChange}
                  className="w-full border border-gray-300 rounded-md p-2"
                />
                {errors[field] && <p className="text-red-500 text-xs mt-1">{errors[field]}</p>}
              </div>
            ))}
            <button type="submit" className="w-full bg-[#02500999] text-white py-2 px-4 rounded-md">Submit</button>
          </form>
        )}

        {activeTab === 'upi' && (
          <form onSubmit={handleUpiSubmit}>
            <h2 className="text-2xl font-bold mb-4 text-center">UPI Details</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">UPI ID</label>
              <input
                type="text"
                name="upiId"
                value={upiFormValues.upiId}
                onChange={handleUpiChange}
                className="w-full border border-gray-300 rounded-md p-2"
              />
              {errors.upiId && <p className="text-red-500 text-xs mt-1">{errors.upiId}</p>}
            </div>
            <button type="submit" className="w-full bg-[#02500999] text-white py-2 px-4 rounded-md">Submit</button>
          </form>
        )}
      </div>
    </div>
    </>
  );
};

export default BankDetails;
