import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { getCustomerOrder } from "../../services/order";

export default function Navbar() {
  const navigate = useNavigate();
  const [confirmLogOut, setConfirmLogOut] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [customerOrdersCount, setCustomerOrdersCount] = useState(0);
  const [customerOrders, setCustomerOrders] = useState([]);
  const [isOrderDetailsModalOpen, setIsOrderDetailsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isMainModalOpen, setIsMainModalOpen] = useState(false);
  const userDetails = useRef(JSON.parse(localStorage.getItem("userDetails")));

  console.log("userDetails:", userDetails);


  const fetchCustomerOrders = async (page) => {
    if (userDetails.current?.role === "agent" && userDetails.current?.agent_id) {
      try {
        const response = await getCustomerOrder({
          agent_id: userDetails.current?.agent_id,
          page_number: page,
        });
        console.log("Customer Data:", response?.data?.data?.orders);
        setCustomerOrdersCount(response?.data?.data?.total);
        setCustomerOrders(response?.data?.data?.orders);
        setIsMainModalOpen(true);
      } catch (error) {
        console.error("Error fetching customer orders:", error);
      }
    }
  };

  const handleGetCustomerOrders = () => {
    fetchCustomerOrders(1); // Start from first page
  };

  const handleViewOrderDetails = (order) => {
    setSelectedOrder(order);
    console.log('selected order-->>', selectedOrder)
    setIsMainModalOpen(false);
    setIsOrderDetailsModalOpen(true);
  };

  const handleBackToMainModal = () => {
    setIsOrderDetailsModalOpen(false);
    setIsMainModalOpen(true);
  };

  const handleNextPage = () => {
    if (pageNo * 10 < customerOrdersCount) {
      setPageNo((prev) => prev + 1);
      fetchCustomerOrders(pageNo + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNo > 1) {
      setPageNo((prev) => prev - 1);
      fetchCustomerOrders(pageNo - 1);
    }
  };

  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-auto flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          <a className="text-white text-sm uppercase hidden lg:inline-block font-semibold" href="#pablo" onClick={(e) => e.preventDefault()}>
            Dashboard
          </a>

          {userDetails.current?.role === "agent" && (
            <button className="get-btn px-5 py-2 rounded-md text-white bg-green-500 hover:bg-green-600" onClick={handleGetCustomerOrders}>
              Get Customer Orders
            </button>
          )}

          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <li className="items-center">
              <div onClick={() => setConfirmLogOut(true)} className="text-xs uppercase py-3 font-bold block cursor-pointer text-blueGray-700 hover:text-lightBlue-600">
                <i className="fas fa-sign-out mr-2 text-lg text-red-600"></i>
              </div>
            </li>
          </ul>
        </div>
      </nav>

      {/* Main Orders Modal */}
      <Modal className="modal-menu modal-menu-3 m-auto relative" isOpen={isMainModalOpen} onRequestClose={() => setIsMainModalOpen(false)} contentLabel="Customer Orders"
        style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.75)" } }}>

        {/* Close Button */}
        <button className="absolute top-4 right-4 text-gray-600 hover:text-gray-900" onClick={() => setIsMainModalOpen(false)}>
          <i className="fas fa-times text-xl"></i>
        </button>

        <div className="p-[40px] cust-order">
          <h1 className="text-center my-4 text-lg font-bold">Customer Orders</h1>
          <div className="overflow-x-auto">
            <table className="w-full table-auto border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 px-4 py-2">Order Number</th>
                  <th className="border border-gray-300 px-4 py-2">Customer Name</th>
                  <th className="border border-gray-300 px-4 py-2">Total Amount</th>
                  <th className="border border-gray-300 px-4 py-2">Order Date</th>
                  <th className="border border-gray-300 px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody>
                { customerOrders && customerOrders.length > 0 ? (
                  customerOrders.map((order) => (
                    <tr key={order?.order_number} className="hover:bg-gray-50">
                      <td className="border border-gray-300 px-4 py-2">{order?.order_number}</td>
                      <td className="border border-gray-300 px-4 py-2">{order?.shipping_name}</td>
                      <td className="border border-gray-300 px-4 py-2">{order?.total_price}</td>
                      <td className="border border-gray-300 px-4 py-2">{order?.datetime}</td>
                      <td className="border border-gray-300 px-4 py-2 text-center">
                        <button className="get-order-details px-4 py-2 rounded-md" onClick={() => handleViewOrderDetails(order)}>
                          View Details
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="border border-gray-300 px-4 py-2 text-center">
                      No orders found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="flex justify-between mt-4">
              <button className={`px-4 py-2 rounded-md ${pageNo === 1 ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600 text-white"}`}
                onClick={handlePrevPage} disabled={pageNo === 1}>
                Previous
              </button>
              <span className="text-lg font-semibold">Page {pageNo}</span>
              <button className={`px-4 py-2 rounded-md ${(pageNo * 10 >= customerOrdersCount) ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600 text-white"}`}
                onClick={handleNextPage} disabled={pageNo * 10 >= customerOrdersCount}>
                Next
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* Order Details Modal */}
      <Modal className="modal-menu m-auto" isOpen={isOrderDetailsModalOpen} onRequestClose={handleBackToMainModal} contentLabel="Order Details"
        style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.75)" } }}>

<Modal
  className="modal-menu modal-menu-4 m-auto w-full h-full flex justify-center items-center"
  isOpen={isOrderDetailsModalOpen}
  onRequestClose={handleBackToMainModal}
  contentLabel="Order Details"
  style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.75)" } }}
>
  <div className="p-4 w-full max-w-4xl h-[90vh] overflow-y-auto bg-white rounded-lg shadow-lg">
    <button
      className="order-det-btn px-5 py-2 rounded-md text-white bg-blue-500 hover:bg-blue-600 mt-4"
      onClick={handleBackToMainModal}
    >
      Back
    </button>
    <h1 className="text-center my-4 text-lg font-bold">Order Details</h1>

    {selectedOrder ? (
      <>
        {/* Order Summary Table */}
        <div className="p-4 border rounded-lg shadow-md bg-white">
          <h2 className="text-lg font-semibold mb-2">Order No.: #{selectedOrder?.order_number}</h2>

          {/* Customer Details */}
          <div className="bg-green-100 p-4 rounded-lg mb-4">
            <p><strong>Billing Name:</strong> {selectedOrder?.shipping_name}</p>
            <p><strong>Phone No.:</strong> {selectedOrder?.phone}</p>
            <p><strong>Email:</strong> {selectedOrder?.email}</p>
            <p><strong>Order Status:</strong> {selectedOrder?.order_status}</p>
            <p><strong>Order Date:</strong> {selectedOrder?.datetime}</p>
            <p><strong>Delivery Date:</strong> {selectedOrder?.delivery_date}</p>
          </div>

          {/* Items List */}
          <h3 className="text-lg font-semibold mb-2">Items List</h3>
          <table className="w-full border border-gray-300 mb-4">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 px-4 py-2">S.NO.</th>
                <th className="border border-gray-300 px-4 py-2">Product Name</th>
                <th className="border border-gray-300 px-4 py-2">Quantity</th>
                <th className="border border-gray-300 px-4 py-2">Unit Price</th>
                <th className="border border-gray-300 px-4 py-2">Discount</th>
              </tr>
            </thead>
            <tbody>
              {selectedOrder?.orderDetails?.map((item, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="border border-gray-300 px-4 py-2">{index + 1}</td>
                  <td className="border border-gray-300 px-4 py-2 font-bold">{item?.title}</td>
                  <td className="border border-gray-300 px-4 py-2">{item?.quantity}</td>
                  <td className="border border-gray-300 px-4 py-2">{item?.price}</td>
                  <td className="border border-gray-300 px-4 py-2">{item?.discount}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pricing Summary */}
          <div className="bg-green-100 p-4 rounded-lg mb-4">
            <p><strong>Total Amount:</strong> Rs {selectedOrder?.total_price}</p>
            <p><strong>Sub Total:</strong> Rs {selectedOrder?.subtotal_price}</p>
            <p><strong>Shipping Charges:</strong> Rs {selectedOrder?.total_shipping || '0.00'}</p>
            <p><strong>Discount:</strong> Rs {selectedOrder?.total_discount || '0.00'}</p>
          </div>
        </div>
      </>
    ) : null}
  </div>
</Modal>

      </Modal>

      {/* Logout Confirmation Modal */}
      <Modal
        className="modal-menu m-auto"
        isOpen={confirmLogOut}
        onRequestClose={() => setConfirmLogOut(false)}
        contentLabel="Log Out"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
      >
        <div className="p-[50px]">
          <h1 className="text-center my-4 text-lg font-bold">Are You Sure want to Log Out?</h1>
          <div className="flex mt-2 mb-1 px-14 gap-10">
            <button className="px-5 py-2 rounded-md text-white bg-red-500 hover:bg-red-600" onClick={() => { localStorage.removeItem("token"); localStorage.removeItem("role"); navigate("/auth/login") }}>Yes</button>
            <button className="px-5 py-2 rounded-md text-white bg-green-500 hover:bg-green-600" onClick={() => setConfirmLogOut(false)}>No</button>
          </div>
        </div>
      </Modal>
    </>
  );
}
