import React, { Suspense, useEffect, useState } from "react";
import Loader from "../components/Loader/Loader";
import FooterAdmin from "../components/Footers/FooterAdmin";
import { useNavigate, useParams } from "react-router-dom";
import { Stepper } from "react-form-stepper";
import { getOrderTrackingData } from "../services/order";
import { toast } from "sonner";
import moment from "moment";

const OrderTrackingInfo = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { orderNumber } = params || {};
    const statusFlow = [
        {
            status: "confirmed",
            label: (
                <>
                    <i className="fa-solid fa-clipboard-check mr-2"></i> Confirmed
                </>
            ),
        },
        // { status: "allocated", label: <><i className="fa-solid fa-box mr-2"></i> Allocated</> },
        {
            status: "packed",
            label: (
                <>
                    <i className="fa-solid fa-box-open mr-2"></i> Dispatched
                </>
            ),
        },
        // { status: "picked", label: <><i className="fa-solid fa-truck-loading mr-2"></i> In Transit</> },
        {
            status: "shipped complete",
            label: (
                <>
                    <i className="fa-solid fa-shipping-fast mr-2"></i> In Transit
                </>
            ),
        },
        {
            status: "out for delivery",
            label: (
                <>
                    <i className="fa-solid fa-shipping-fast mr-2"></i> Out For Delivery
                </>
            ),
        },
        {
            status: "delivered",
            label: (
                <>
                    <i className="fa-solid fa-house-user mr-2"></i> Delivered
                </>
            ),
        },
    ];

    const CancelledStatusFlow = [
        {
            status: "initiated",
            label: (
                <>
                    <i className="fa-solid fa-rotate mr-2"></i> Refund Initiated
                </>
            ),
        },
        {
            status: "approved",
            label: (
                <>
                    <i className="fa-solid fa-check-circle mr-2"></i> Refund Approved
                </>
            ),
        },

        
    ];


    const [orderDetail, setOrderDetail] = useState({});
    const [orderNo, setOrderNo] = useState("");



    const fetchOrderTrackingData = async (orderNumberToFetch) => {
        if (!orderNumberToFetch) {
            toast.error("Enter a valid order number");
            return;
        }

        const response = await getOrderTrackingData({
            order_number: orderNumberToFetch,
        });
        const { data, status } = response || {};
        console.log('data ---->>>,<<<<<', data)

        if (status === 200 || status === 201) {
            setOrderDetail(data?.data);
            navigate(`/orders/tracking/${orderNumberToFetch}`);
        } else if (status === 404) {
            toast.error(data?.message);
            setOrderNo("");
            navigate(`/orders/tracking`);
        } else {
            toast.error("Something went wrong. Please try again.");
        }
    };

    useEffect(() => {
        // Automatically fetch data when params.orderNumber changes
        if (orderNumber) {
            fetchOrderTrackingData(orderNumber);
        }
    }, [orderNumber]);

    const handleButtonClick = () => {
        fetchOrderTrackingData(orderNo);
    };

    const getActiveStep = () => {
        const orderStatus = orderDetail?.order?.order_status?.toLowerCase();
         if(orderStatus === "cancelled") {
            return 0;
         }
        

        if (orderStatus === "pick complete") {
            return statusFlow.findIndex(
                (step) =>
                    typeof step.status === "string" &&
                    step.status.toLowerCase() === "shipped complete"
            );
        }
        
        if (orderStatus === "partially shipped") {
            return statusFlow.findIndex(
                (step) =>
                    typeof step.status === "string" &&
                    step.status.toLowerCase() === "shipped complete"
            );
        }

        if (orderStatus === "part allocated") {
            // console.log('orderStatus---------- ', orderStatus);
            return statusFlow.findIndex(
                (step) =>
                    typeof step.status === "string" &&
                    step.status.toLowerCase() === "confirmed"
            );
        }

        if (orderStatus === "allocated") {
            // console.log('orderStatus---------- ', orderStatus);
            return statusFlow.findIndex(
                (step) =>
                    typeof step.status === "string" &&
                    step.status.toLowerCase() === "confirmed"
            );
        }

        return statusFlow.findIndex(
            (step) =>
                typeof step.status === "string" &&
                step.status.toLowerCase() === orderStatus
        );
    };

    const getCancelActiveStep = () => {
        const refundStatus = orderDetail?.refundDetail?.refund_status?.toLowerCase();

        return CancelledStatusFlow.findIndex(
            (step) =>
                typeof step.status === "string" &&
                step.status.toLowerCase() === refundStatus
        );
    };

    console.log("active step data-->>>", getActiveStep());
    const isCancelled = orderDetail?.order?.order_status?.toLowerCase() === "cancelled";
    const isRefundExist = orderDetail?.refundDetail ? true : false;
    console.log("getCancelActiveStep ->>>", getCancelActiveStep());
  


    return (
        <Suspense fallback={<Loader />}>
            {!orderNumber ? (
                <>
                    <div className="tracking-page">
                        <nav class="p-[10px] navbar bg-body-tertiary logo-track">
                            <div class="container">
                                <a class="navbar-brand" href="https://www.daburshop.com/">
                                    <img
                                        src="https://www.daburshop.com/cdn/shop/files/Dabur-Logo.wine_1.png?v=1724404379&width=90"
                                        alt="Bootstrap"
                                        width="60"
                                    />
                                </a>
                            </div>
                        </nav>
                        <hr />

                        <div className=" w-full z-10 flex justify-center items-center py-12 h-[70vh]">
                            <div className="flex flex-col gap-3 w-[400px] h-[65px]">
                                <h1 className="text-center text-[30px] font-bold">
                                    Track Your Order
                                </h1>
                                <input
                                    type="text"
                                    className="text-black rounded-md"
                                    onChange={(e) => setOrderNo(e?.target?.value)}
                                    placeholder="Enter Order ID (Example: 1001)"
                                />
                                <button
                                    className="px-4 py-2 tracking-btn rounded-md"
                                    onClick={handleButtonClick}
                                >
                                    Get Tracking Data
                                </button>
                            </div>
                        </div>
                        <FooterAdmin />
                    </div>
                </>
            ) : (
                <div className="relative">
                    <div className="delivertracking-detail">
                        <nav class="p-[10px] navbar bg-body-tertiary logo-track">
                            <div class="container">
                                <a class="navbar-brand" href="https://www.daburshop.com/">
                                    <img
                                        src="https://www.daburshop.com/cdn/shop/files/Dabur-Logo.wine_1.png?v=1724404379&width=90"
                                        alt="Bootstrap"
                                        width="60"
                                    />
                                </a>
                            </div>
                        </nav>
                        <hr className="line-partion" />
                        <div>
                            <h3 className="text-center text-[25px] font-bold pt-10 pb-12">
                                Your Order Tracking Status
                            </h3>
                        </div>
                        <div className="order-status-detail">
                            <div className="text-centers">
                                <p className="mt-2 text-[13px]">
                                    <i class="fa-solid fa-cart-shopping"></i> <b>Order No : </b>{" "}
                                    <span className="font-bold text-custom-green">
                                        {" "}
                                        #{orderNumber || "#00"}
                                    </span>
                                </p>
                            </div>
                            <div className="text-centers">
                                <p className="mt-2 text-[13px]">
                                    <i class="fa-solid fa-truck"></i> <b>Order Status : </b>{" "}
                                    <span className="font-bold text-custom-green">
                                        {orderDetail?.order?.order_status?.toUpperCase() || "--"}
                                    </span>
                                </p>
                            </div>
                            <div className="text-centers">
                                <p className="mt-2 text-[13px]">
                                    <i className="fa-solid fa-house-user"></i>{" "}
                                    <b>Expected Delivery Date : </b>
                                    <span className="font-bold text-custom-green">
                                        {orderDetail?.order?.cust_delivery_date
                                            ? moment(orderDetail?.order?.cust_delivery_date).format(
                                                "YYYY-MM-DD"
                                            )
                                            : "--"}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="px-4 mx-auto w-full overflow-x-auto pt-12 pb-12 stepper">
                        {((isCancelled && isRefundExist) || !isCancelled) && (
                                <Stepper
                                    steps={(isCancelled && isRefundExist ? CancelledStatusFlow :  statusFlow).map(
                                        (step) => ({
                                            label: (
                                                <span className="flex items-center text-[13px]">
                                                    <i icon={step.icon} className="mr-2 text-green-500" />
                                                    {step.label}
                                                </span>
                                            ),
                                        })
                                    )} // Dynamically choose the appropriate flow
                                    activeStep={ isCancelled && isRefundExist ? getCancelActiveStep() : getActiveStep()}
                                    className="p-0"
                                    connectorStateColors={true}
                                    connectorStyleConfig={{
                                        completedColor: "green",
                                        disabledColor:  "gray",
                                        size: 4,
                                        activeColor: "green",
                                    }}
                                    styleConfig={{
                                        activeBgColor: "green",
                                        activeTextColor: "green",
                                        inactiveTextColor: "gray",
                                        inactiveBgColor: "gray",
                                        completedTextColor: "green",
                                        completedBgColor: "green",
                                        circleFontSize: "0px",
                                        size: "0.7em",
                                        labelFontSize: "12px",
                                    }}
                                />

                            )}

                        </div>
                        <FooterAdmin />
                    </div>
                </div>
            )}
        </Suspense>
    );
};

export default OrderTrackingInfo;
